@media only screen and (max-width: 1280px) {
    .privacy{
        display: none;
    }
}

@media only screen and (max-width: 1024px) {
}

@media (min-width: 768px) and (max-width: 992px) {
    .signin{
        top: 3%;
        right: 3%;
        width: 40px;
        height: 40px;
    }
    .nav .pointer-cursor{
        top: 3%;
        right: 10%;
        width: 40px !important;
        height: 40px !important;
    }
    .send {
        right: 11%;
    }
}

@media only screen and (max-width: 767px) {
    .profile-bg {
        margin: 0 auto;
    }
    .logo-width img{
        width: 100%;
    }
    .signin{
        top: 4%;
        right: 3%;
    }
    .nav .pointer-cursor{
        top: 4%;
        right: 10%;
    }
    .send {
        right: 22%;
    }
    .chat-text-box textarea{
        font-size: 13px;
    }
}

@media only screen and (max-width: 540px){
    .profile-bg {
        width: 31%;
    }
}

@media (min-width: 390px) and (max-width: 500px) {
    .profile-bg{
        width: 43%;
    }
}

@media screen and (max-width: 375px) {
    .profile-bg{
        width: 50%;
    }
    .text p {
        font-size: 14px;
    }
    .signin{
        top: 5%;
        right: 3%;
    }
    .nav .pointer-cursor{
        top: 5%;
        right: 10%;
    }
}