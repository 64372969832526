#root {
  padding: 0;
  margin: 0;
  /* background-color: #f8f9fa !important; */
  width: 99%;
  height: 100vh;
}

/* * {
  font-family: Roboto !important;
} */

.sidebar-container {
  position: fixed;
  right: 0;
  height: 100vh;
  padding: 0;
  /* border-left: 1px solid rgba(172, 169, 169, 0.562); */
  box-shadow: 1px 10px 6px #d1d3d5;
  /* filter: drop-shadow(1px 10px 6px #d1d3d5); */
}

.chat-text-box {
  position: fixed;
  bottom: 0;
  width: 50%;
  padding: 20px;
}

@media only screen and (max-width: 1440px) {
  .chat-text-box {
    position: fixed;
    bottom: 0;
    width: 75%;
    padding: 20px;
  }
}

.hr-shadow {
  box-shadow: 1px 0px 0px 0px;
}

.pointer-cursor {
  cursor: pointer;
}

.fdd:focus-visible {
  border: 0px !important;
}

.newchat {
  display: flex;
}

.newchat .newchat-label p {
  width: fit-content;
}

.typewriter {
  display: inline-block;
  font-family: monospace;
  white-space: nowrap;
  overflow: hidden;
  width: 95%;
  /* border-right: 0.15em solid black; */
  white-space: pre-wrap;
  /* Allow text to wrap */
  word-break: break-word;
  /* Break long words if necessary */
  overflow: hidden;
}

.cursor {
  display: inline-block;
  background-color: black;
  width: 10px;
  animation: blink 0.7s steps(1) infinite;
  margin-left: 5px;
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

/* .chat-container {
  height: 80vh;
  width: 100px;
} */

.chat-part {
  height: 95%;
  /* overflow-y: auto; */
}

.chat-part::-webkit-scrollbar {
  width: 15px;
}

.newchat {
  cursor: pointer;
}

/* Keerthy CSS Start */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

p,
span {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.card-body-style {
  box-shadow: 0px 5px 5px 0 #e0e0e0;
  padding: 20px;
  border-radius: 8px;
  background-color: white;
}

.profile-user {
  background-color: rgb(108, 23, 23);
  border-radius: 50%;
  padding: 10px;
  color: white;
  margin-bottom: 8px;
}

.info-card {
  padding: 10px;
  border: solid 1px #dad3d35c;
  border-radius: 8px;
  background-color: white;
  margin-top: 15px;
}

.content-end {
  text-align: end;
}

.content-end h5 {
  margin-bottom: 20px;
}

.location-i {
  color: rgb(3, 113, 27);
  margin-right: 6px;
}

/* .location-details p {
  text-align: end;
  margin-bottom: 5px;
} */
.location-details {
  padding: 0 20px;
}

.location-details h5 {
  font-size: 16px;
}

.file-icon {
  padding: 12px 15px;
  border-radius: 50%;
  color: #612820;
  background-color: #cb794642;
  font-size: 23px;
}

.dashboard-card span {
  color: rgba(101, 96, 96, 0.809);
  font-size: 16px;
}

.dashboard-card h6 {
  font-size: 28px;
  font-weight: 600;
  margin: 0;
}

.dashboard-card {
  margin: 0 0 20px 0;
}

.mar-bottm {
  margin-bottom: 30px;
}

.mar-top {
  margin-top: 25px;
}

.content-center {
  display: table;
  margin: 0 auto;
  text-align: center;
}

.borderhr hr {
  border-bottom: 1px solid #00000033;
  margin: 20px 20px 30px 20px;
}

.margin-top {
  margin-top: 20px;
}

.hr-style hr {
  border-bottom: 1px solid #00000033 !important;
  margin: 15px 0 10px 0;
}

.lawyer-dashboard {
  padding: 60px 30px 30px 30px;
}

.location-details span {
  padding-right: 10px;
}

.align-self {
  align-self: center;
}

.case-details-sec {
  padding: 50px 30px 0 30px;
}

.submit-btn {
  background-color: #612820;
  border: none;
  margin: 15px 0 0 0;
}

.case-details-form form {
  margin: 10px;
}

.css-10botns-MuiInputBase-input-MuiFilledInput-input {
  background: white !important;
}

.add-file-div {
  padding: 9px 6px;
}

.file-margin {
  margin: 6px 0 6px 0;
}

.label-style-form {
  font-size: 15px !important;
  color: #121416 !important;
  padding: 5px 0 !important;
}

.case-card-de {
  box-shadow: 0px 3px 3px 0 #e0e0e0;
  padding: 20px;
  border-radius: 8px;
  background-color: white;
  margin-bottom: 20px;
  border: solid 1px #b5abab26;
}

.flex-casetype {
  display: flex;
  justify-content: space-between;
}

.wid-50 {
  width: 40%;
}

.case-file-i {
  font-size: 16px;
  padding: 6px 8px;
  color: white;
  border-radius: 50%;
  background-color: #612820;
  margin-right: 10px;
  vertical-align: middle !important;
}

.case-card-de h5 {
  margin-bottom: 15px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.5;
}

.case-card-de span {
  color: #612820;
  font-size: 17px;
  font-weight: 500 !important;
  margin-right: 5px;
}

.case-card-de p {
  margin-bottom: 12px;
  color: #646060;
  line-height: 1.7;
  margin-bottom: 15px;
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}

.pdf-download {
  background-color: rgb(217 220 225 / 38%);
  padding: 12px 6px;
  border-radius: 8px;
  width: 50%;
  display: flex;
  align-items: center;
  margin-top: 15px;
  justify-content: center;
}

.download-icon {
  font-size: 16px;
  padding: 6px 8px;
  color: white;
  border-radius: 50%;
  background-color: #612820;
  margin-right: 10px;
}

.pdf-download span {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.5;
  margin: 0;
}

.p-align {
  text-align: justify;
}

.btn-iconstyle {
  margin-right: 10px;
}

body {
  background-color: #f8f9fa;
}

/* Keerthy CSS  End */

/*thulasi*/
.hr-shadow {
  display: none;
}

.chat-part img {
  display: none;
}

.privacy {
  position: absolute;
  left: 0;
  bottom: 35px;
}

.privacy ul {
  display: flex;
  margin-right: 30px;
}

.privacy ul li {
  list-style-type: none;
  padding-right: 20px;
}

.chat {
  width: 75%;
  background: white;
}

.nav .pointer-cursor {
  position: absolute;
  right: 3%;
  border: 1px solid;
  width: 30px;
  height: 30px;
  padding: 3px;
  color: #612820;
  border-radius: 50%;
  top: 4%;
}

.message {
  color: #612820;
}

.fixed {
  width: 56%;
}

.ai-chat {
  display: flex;
  width: 50%;
}

.ai-icon {
  margin-right: 10px;
  border: 1px solid #612820;
  padding: 5px;
  color: white;
  border-radius: 5px;
  background: #612820;
  width: 20px;
  height: 20px;
}

.ai-text-info {
  background: #fcfcfc;
  border-radius: 5px;
  line-height: 2;
  color: #612820 !important;
  padding: 0 12px;
  box-shadow: 0px 5px 5px 0 #e0e0e0;
  font-size: 14px;
  width: 100%;
}

.user-text-info {
  background: #efefef;
  border-radius: 5px;
  line-height: 2;
  color: #612820 !important;
  padding: 0 12px;
  box-shadow: 0px 5px 5px 0 #e0e0e0;
  font-size: 14px;
}

.text-info p {
  margin: 0 !important;
}

.user-icon {
  border: 1px solid #9a5225;
  padding: 5px;
  color: white;
  border-radius: 5px;
  background: #9a5225;
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.user-chat {
  display: flex;
  justify-content: end;
  margin: 20px 40px 20px 0;
}

.bd {
  border: 1px solid;
  padding: 30px;
  width: 97%;
  color: #ececec;
  border-radius: 10px;
  background: white;
}

.scroll {
  overflow-y: scroll;
  width: 100%;
  height: 650px;
}

.bg {
  background: #f1f1f1;
  width: 80%;
  height: 100%;
  border-radius: 10px 0 0 10px;
  color: white;
}

.bg-width {
  display: flex;
  justify-content: end;
  width: 25%;
  margin-top: 40px;
}

.history {
  padding: 10px 10px 30px 0;
  overflow-y: scroll;
  width: 100%;
  height: 690px;
  color: #612820;
  font-size: 14px;
}

.history span {
  color: #612820a1;
}

.history ul li {
  list-style-type: none;
  padding-top: 7px;
}

.law {
  padding-right: 8px;
  font-size: 18px;
}

.history-content {
  padding-top: 20px;
}

.history hr {
  width: 80px;
  margin: 5px 0;
  color: #f1bd27;
}

.chat-bar {
  resize: none;
  line-height: 0.5;
  padding: 20px 10px !important;
}

.chat-screen {
  margin-top: 30px;
}

.head-bg {
  background: #f1f1f1;
}

.signin {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 1%;
  top: 4%;
  color: #612820;
}

.send {
  position: absolute;
  right: 7%;
  border: 1px solid;
  width: 30px;
  height: 30px;
  padding: 3px;
  color: #612820;
  border-radius: 50%;
  top: 36%;
}

.bd p {
  margin-bottom: 0;
  margin-top: 2px;
  padding: 5px 0;
}

.profile {
  margin: 30px 0;
}

.circle {
  border: 2px solid;
  border-radius: 50%;
  padding: 40px;
  width: 30px;
  height: 30px;
  margin: 20px auto;
  display: table;
  color: #c8c4c4;
  cursor: pointer;
}

.profile-title {
  font-size: 22px;
  color: #612820;
}

.profile-form .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  line-height: 0.8;
  font-size: 15px;
  color: rgb(182 174 174);
}

.profile-form .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10px 5px;
}

/*thulasi*/
.profile-form .MuiInputBase-input {
  padding: 10px 5px;
}
.profile-form .row {
  margin-bottom: 15px;
}
.update {
  background: #612820;
  border: none;
  padding: 8px 20px;
  font-size: 15px;
  margin-right: 10px;
}
.update:hover {
  background: white;
  color: #612820;
  border-color: #612820;
  border: 1px solid;
}
.cancel {
  background: #612820;
  border: none;
  padding: 8px 20px;
  font-size: 15px;
}
.cancel:hover {
  background: white;
  color: #612820;
  border-color: #612820;
  border: 1px solid;
}
.profile-form .color {
  color: rgb(134 129 129 / 38%);
}
.profile-img {
  width: 30%;
  height: auto;
  margin: 0 auto;
  display: table;
  border-radius: 50%;
  border: 1px solid;
  color: #c8c4c4;
}
.image {
  position: relative;
}
/* .profile-bg::after {
  background-color: rgb(0 0 0 / 45%);
  z-index: 5;
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 50%;
} */
.circle {
  position: absolute;
  right: 0;
  left: 0;
  margin: 0 auto;
  z-index: 10;
  color: rgb(209, 209, 209);
  top: 22px;
  bottom: 0;
  cursor: pointer;
}
.profile-col {
  margin: 0 auto;
}
.profile-bg {
  border-radius: 50%;
  padding: 20px 10px;
  width: 100%;
  height: 119px;
}
.form-space {
  margin: 15px 0;
}
.form-space1{
  margin: 15px 0;
  text-align: right;
}
.login {
  font-size: 20px;
  color: #612820;
}
.submit-btn {
  margin-top: 20px;
  background: #612820;
  border: none;
  padding: 8px 20px;
  font-size: 15px;
}
.submit-btn:hover {
  background: white;
  color: #612820;
  border-color: #612820;
  border: 1px solid;
}
.text p {
  text-align: center;
  color: #8e8d8d;
  font-size: 15px;
  margin: 20px 0 0 0;
}
.image-center img {
  margin: 0 auto;
}
.position {
  position: absolute;
  right: 0;
}
/*thulasi*/
