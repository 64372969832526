@media only screen and (max-width: 1280px) {}

@media only screen and (max-width: 1024px) {
    .pdf-download {
        width: 100%;
    }
    .file-icon {
        padding: 9px 12px;
        font-size: 16px;
    }
    .dashboard-card h6 {
        font-size: 24px;
    }
    .dashboard-card span {
        font-size: 14px;
    }
    .spacebet-res{
        justify-content: space-between;
    }
    .card-1024res{
        margin-top: 20px;
    }
}

@media only screen and (max-width: 992px) {

    /* .pdf-download {
        width: 100%;
    } */
    .responsive-margin {
        margin-top: 20px;
    }
}

@media only screen and (max-width: 767px) {
    .mar-top-res {
        margin-top: 26px;
    }
}

/* @media only screen and (max-width: 720px) {
    .mar-top-res {
        margin-top: 26px;
    }
} */

@media only screen and (max-width: 540px) {
    .mar-top-res {
        margin-top: 26px;
    }
    .solved-responsive{
        margin-top: 20px;
    }
}